import { useStaticQuery, graphql } from 'gatsby';

const shopGet = (shop: string) => {
  const shopdataquery = useStaticQuery(graphql
    `query{
        allMicrocmsShopRph (sort: {fields: sortIndex, order: ASC}){
          edges {
            node {
              title
              hours{
                fieldId
                keyname
                value
              }
              private{
                fieldId
                keyname
                value
              }
              smork{
                fieldId
                keyname
                value
              }
              note{
                fieldId
                keyname
                value
              }
              private_fee{
                fieldId
                keyname
                value
              }
              private_mealfee{
                fieldId
                keyname
                value
              }
              grand_menu{
                menu_title
                menu_link {
                  fieldId
                  blank
                  href
                  pdf {
                    url
                  }
                }
              }
              shopRphId
            }
          }
        }
      }`
  )
  const shopdata = shopdataquery.allMicrocmsShopRph.edges
  return shopdata.filter((item: any) => {
    if (item.node.shopRphId == shop) {
      return true
    } else {
      return false
    }
  })[0].node
}

export default shopGet