import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CHeroSlider03,
  CDefinition,
  CShopInfo,
  CGrandMenu,
  CShopPrivate,
  CDefinitionAndBtn,
  CDefinitionAndBtn02,
  CRestaurantPlanRecommend,
  CRestaurantJumbotron,
  CBtnList,
  CInfinitySlider,
  LContact,
  LContact02,
  LContact03,
  LStores,
  CVariableSlider,
  CInfinitySlider02,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import restaurantPlanChoice from '../../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const shopdata = shopGet('kei_ka_en');

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        data={{
          logo: {
            src: '/assets/images/restaurants/kei_ka_en/kei_ka_en_logo.svg',
            alt: '',
            widthSp: '184px',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/kei_ka_en/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/kei_ka_en/kv__sp.png',
              },
            },
          ],
        }}
      ></CRestaurantJumbotron>
      <LContact02
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-keikaen/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
      />
      <div className="u_pt100 u_mbLarge" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CHeroSlider03
            exClass="slick-aspect-1"
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en02__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en03__sp.png',
                    alt: '',
                  },
                },
              ],
              title: (
                <>クラシカルさとスタイリッシュなモダン空間で食の広州を感じて</>
              ),
              text: (
                <>
                  中国を感じさせるアートワークをコーディネートしたモダンで落ち着いた空間。
                  <br />
                  開放的なオープンキッチンに面したカウンター席や多種多様な個室、ゆったりとしたソファ席もあり、シーンに合わせてご利用いただけます。
                </>
              ),
            }}
          />
          <CHeroSlider03
            exClass="slick-aspect-1 u_mb100"
            reverse={true}
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en04.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en04__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en05.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en05__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en06.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en06__sp.png',
                    alt: '',
                  },
                },
              ],
              text: (
                <>
                  「食は広州にあり」といわれるほど、奥が深く、またバラエティに富む広東料理。
                  <br />
                  魚介類と高度な技術が揃えばこそ創りだすことができる豊かな味わいを、心ゆくまでご賞味ください。
                </>
              ),
            }}
          />
          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: '店舗情報',
            }}
          />
          <p className="u_tac_sp">
            最新の営業時間は、<a href="/information/timeindex/">こちら</a>
            をご覧ください。
          </p>
          <CShopInfo data={shopdata} exClass="u_mt20" />
        </LWrap>
      </div>
      <section className="l_sect u_bgGray" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CSectTitle
            title={{
              en: 'MENU',
              ja: 'メニュー',
            }}
          />
          <CRestaurantPlanRecommend
            data={restaurantPlanChoice(
              restaurantPlanGet(),
              ['中国料理 桂花苑'],
              0
            )}
          />
          <CBtnList
            data={[
              {
                label: '他のプランを見る',
                link: {
                  href: '/restaurants/plan/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
          <CGrandMenu data={shopdata} exClass="u_mt70 u_mb20" />
          <ul className="c_noteList u_mbMedium">
            <li>季節により内容が変わります。</li>
          </ul>
        </LWrap>
      </section>
      <section className="l_sect u_pb40_sp">
        <LWrap>
          <CSectTitle
            title={{
              en: 'PRIVATE ROOM',
              ja: '個室',
            }}
          />
          <CVariableSlider
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en10.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en11.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/kei_ka_en/img_kei_ka_en12.png',
                  alt: '',
                },
              },
            ]}
          />
          <CShopPrivate
            data={shopdata}
            btn={{
              label: 'レストラン個室のご案内',
              link: {
                href: '/restaurants/private/',
              },
            }}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />

      <LContact03
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-keikaen/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/restaurants/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
      <LStores />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
